import { z } from "zod";

export const MY_NEAR_WALLET_SESSION_TOKEN_KEY = "myNearWalletSessionToken";

// A pseuedo-random array of 32 bytes that is used for message signing verification with Near
export const getNearNonce = () => {
  const nonce = Buffer.from("dgMGbH0LHIEy7kSSwhVQdkV4t28Ru36IDy5NmvFpA2M=", "base64");
  return nonce;
};

export const nearSignMessage = "Sign this message to verify your address with Flipside";

export const nearRecipient = "flipside";

export const myNearWalletStateSchema = z.object({
  callbackUrl: z.string(),
  redirectUrl: z.string(),
});
